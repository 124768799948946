"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Access_1 = require("./Access");
Object.defineProperty(exports, "Access", {
  enumerable: true,
  get: function () {
    return Access_1.Access;
  }
});
