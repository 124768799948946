"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Possession = void 0;
exports.Possession = {
  OWN: 'own',
  ANY: 'any'
};
