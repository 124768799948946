"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Permission_1 = require("./Permission");
Object.defineProperty(exports, "Permission", {
  enumerable: true,
  get: function () {
    return Permission_1.Permission;
  }
});
