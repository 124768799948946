"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Query_1 = require("./Query");
Object.defineProperty(exports, "Query", {
  enumerable: true,
  get: function () {
    return Query_1.Query;
  }
});
