"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AccessControlError_1 = require("./AccessControlError");
Object.defineProperty(exports, "AccessControlError", {
  enumerable: true,
  get: function () {
    return AccessControlError_1.AccessControlError;
  }
});
